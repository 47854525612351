import type { NextPage, GetStaticProps } from 'next'
import { ApplicationsHbbTVPage } from "@components/webTechnology/PageTypes/UneditablePage/pages/applications/ApplicationsHbbTVPage/ApplicationsHbbTVPage";

const Page: NextPage = () => {
  return <ApplicationsHbbTVPage />;
};

export default Page;

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  }
}
